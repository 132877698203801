/* eslint-disable import/no-cycle */
// Imports
import React, { useEffect, useState, useContext} from 'react';
import { Link, navigate } from 'gatsby';
import { myContext } from './Provider';

// CSS
import * as NavStyles from './css/Nav.module.css';

// SVG
import SidebarLogo from '../assets/images/bb-logo-sidebar.svg';
import Flag from '../assets/images/flag-icon.svg';
import Close from '../assets/images/icons/icon_close.svg';
import UKActiveLogo from '../assets/images/uk-active-member.svg';
import IndependentGymsLogo from '../assets/images/independent-gyms.svg';

// Components
import Button from './Button';

// Setup navigation UI on refesh or on directly going to a url like /reformers
const isBrowser = typeof window !== 'undefined';
const path = isBrowser ? window.location.pathname : null;
let accordianOpen = 0;
let selectedCategory = '';
if (isBrowser && (path === '/reformers' || path === '/reformers/')) {
	accordianOpen = 1;
	selectedCategory = 'reformers';
} else if (isBrowser && (path === '/chairs' || path === '/chairs/')) {
	accordianOpen = 1;
	selectedCategory = 'chairs';
} else if (isBrowser && (path === '/barrels-&-arcs' || path === '/barrels-&-arcs/')) {
	accordianOpen = 1;
	selectedCategory = 'barrels-&-arcs';
} else if (isBrowser && (path === '/equipment-accessories' || path === '/equipment-accessories/')) {
	accordianOpen = 2;
	selectedCategory = 'equipment-accessories';
} else if (isBrowser && (path === '/konnector-kits' || path === '/konnector-kits/')) {
	accordianOpen = 2;
	selectedCategory = 'konnector-kits';
} else if (isBrowser && (path === '/motr' || path === '/motr/')) {
	accordianOpen = 4;
	selectedCategory = 'motr';
} else if (isBrowser && (path === '/corealign' || path === '/corealign/')) {
	accordianOpen = 4;
	selectedCategory = 'corealign';
} else if (isBrowser && (path === '/orbit' || path === '/orbit/')) {
	accordianOpen = 4;
	selectedCategory = 'orbit';
} else if (isBrowser && (path === '/studio-furnishings' || path === '/studio-furnishings/')) {
	accordianOpen = 5;
	selectedCategory = 'apparel';
} else if (isBrowser && (path === '/all' || path === '/all/')) {
	accordianOpen = 6;
	selectedCategory = 'all';
}

// ======== Component ========
const Nav = props => {
	const {
		handleMobileNavOpen,
		searchValue,
	} = props;

	// State
	const contextValue = useContext(myContext);
	const [openAccordian, setOpenAccordian] = useState(accordianOpen);
	const [selectedPath, setSelectedPath] = useState(selectedCategory);

	// Top level accordian links
	const handleAccordianOpen = (val, link) => {
		if (val === openAccordian) {
			setOpenAccordian(0);
		} else {
			navigate(`/${ link }`);
			setOpenAccordian(val);
			setSelectedPath(link);
		}
	};

	// Sub nav links
	const handleSubNav = link => {
		navigate(`/${ link }`);
		setSelectedPath(link);
		handleMobileNavOpen();
	};

	// Close accordion when searching
	useEffect(() => {
		if (!searchValue) return;
		setOpenAccordian(0);
	}, [searchValue]);

	return (
		<nav className={`flex flex-jc-fs ${ NavStyles.nav }`}>
			{/* Logo */}
			<div className={`flex w100 ${ NavStyles.logoContainer }`}>
				<Link
					to={'/'}
					onClick={() => {
						setOpenAccordian(0);
						setSelectedPath('');
					}}
					aria-label={'Navigate to home page'}
				>
					<SidebarLogo className={NavStyles.sidebarLogo} />
				</Link>
				<p className={`small-text ${ NavStyles.sidebarSubtitle }`}>United Kingdom</p>
			</div>

			{/* Button to close mobile nav */}
			<button
				aria-label={'close'}
				onClick={() => { handleMobileNavOpen(); }}
				className={`flex flex-ai-fe w100 ${ NavStyles.closeMenuContainer }`}
			>
				<Close className={NavStyles.close} />
			</button>

			{/* List */}
			<ul className={NavStyles.navTopList}>
				{/* Remove lines 112 - 128 after sale is over */}
				<li className={NavStyles.navTopListItem}>
					<button
						className={`h4 ${ NavStyles.navTopListItemButton }`}
						onClick={() => { handleAccordianOpen(8, 'sale'); }}
					>
						<b>The Pilates Gathering Demo Equipment Sale</b>
					</button>
				</li>
				{/* Sale Item */}
				{/* <li className={NavStyles.navTopListItem}>
          <button
            className={`h4 ${NavStyles.navTopListItemButton}`}
            onClick={() => { handleAccordianOpen(8, "sale-5-off"); }}>
            <b>Sale 5% Off</b>
          </button>
        </li> */}

				{/* Sale Item */}
				{/* <li className={NavStyles.navTopListItem}>
          <button
            className={`h4 ${NavStyles.navTopListItemButton}`}
            onClick={() => { handleAccordianOpen(9, "sale-15-off"); }}>
            <b>Sale 15% Off</b>
          </button>
        </li> */}

				{/* Item */}
				<li className={NavStyles.navTopListItem}>
					<button
						className={`h4 ${ NavStyles.navTopListItemButton }`}
						onClick={() => { handleAccordianOpen(1, 'reformers'); }}
					>
						Pilates Equipment
					</button>
					{/* Sublist */}
					<ul className={openAccordian === 1
						? NavStyles.navSubList
						: NavStyles.navSubListCollapsed}
					>
						<li className={NavStyles.navSubListItem}>
							<button
								className={`body-text ${ NavStyles.navSubListItemButton }`}
								style={selectedPath === 'reformers' ? { fontWeight: '700' } : null}
								onClick={() => { handleSubNav('reformers'); }}
							>
								Reformers
							</button>
						</li>
						<li className={NavStyles.navSubListItem}>
							<button
								className={`body-text ${ NavStyles.navSubListItemButton }`}
								style={selectedPath === 'trapeze-and-towers' ? { fontWeight: '700' } : null}
								onClick={() => { handleSubNav('trapeze-and-towers'); }}
							>
								Trapeze and Towers
							</button>
						</li>
						<li className={NavStyles.navSubListItem}>
							<button
								className={`body-text ${ NavStyles.navSubListItemButton }`}
								style={selectedPath === 'chairs' ? { fontWeight: '700' } : null}
								onClick={() => { handleSubNav('chairs'); }}
							>
								Chairs
							</button>
						</li>
						<li className={NavStyles.navSubListItem}>
							<button
								className={`body-text ${ NavStyles.navSubListItemButton }`}
								style={selectedPath === 'barrels-&-arcs' ? { fontWeight: '700' } : null}
								onClick={() => { handleSubNav('barrels-&-arcs'); }}
							>
								Barrels & Arcs
							</button>
						</li>
						<li className={NavStyles.navSubListItem}>
							<button
								className={`body-text ${ NavStyles.navSubListItemButton }`}
								style={selectedPath === 'more-pilates-equipment' ? { fontWeight: '700' } : null}
								onClick={() => { handleSubNav('more-pilates-equipment'); }}
							>
								More Pilates Equipment
							</button>
						</li>
					</ul>
				</li>

				{/* Item */}
				<li className={NavStyles.navTopListItem}>
					<button
						className={`h4 ${ NavStyles.navTopListItemButton }`}
						onClick={() => { handleAccordianOpen(2, 'equipment-accessories'); }}
					>
						Equipment Accessories
					</button>
					{/* Sublist */}
					<ul className={openAccordian === 2
						? NavStyles.navSubList
						: NavStyles.navSubListCollapsed}
					>
						<li className={NavStyles.navSubListItem}>
							<button
								className={`body-text ${ NavStyles.navSubListItemButton }`}
								style={selectedPath === 'equipment-accessories' ? { fontWeight: '700' } : null}
								onClick={() => { handleSubNav('equipment-accessories'); }}
							>
								Other Accessories
							</button>
						</li>
						<li className={NavStyles.navSubListItem}>
							<button
								className={`body-text ${ NavStyles.navSubListItemButton }`}
								style={selectedPath === 'konnector-kits' ? { fontWeight: '700' } : null}
								onClick={() => { handleSubNav('konnector-kits'); }}
							>
								Konnector Kits
							</button>
						</li>
					</ul>
				</li>

				{/* Item */}
				<li className={NavStyles.navTopListItem}>
					<button
						className={`h4 ${ NavStyles.navTopListItemButton }`}
						onClick={() => { handleAccordianOpen(4, 'motr'); }}
					>
						Fitness Equipment
					</button>
					{/* Sublist */}
					<ul className={openAccordian === 4
						? NavStyles.navSubList
						: NavStyles.navSubListCollapsed}
					>
						<li className={NavStyles.navSubListItem}>
							<button
								className={`body-text ${ NavStyles.navSubListItemButton }`}
								style={selectedPath === 'motr' ? { fontWeight: '700' } : null}
								onClick={() => { handleSubNav('motr'); }}
							>
								MOTR
							</button>
						</li>
						<li className={NavStyles.navSubListItem}>
							<button
								className={`body-text ${ NavStyles.navSubListItemButton }`}
								style={selectedPath === 'corealign' ? { fontWeight: '700' } : null}
								onClick={() => { handleSubNav('corealign'); }}
							>
								CoreAlign
							</button>
						</li>
						<li className={NavStyles.navSubListItem}>
							<button
								className={`body-text ${ NavStyles.navSubListItemButton }`}
								style={selectedPath === 'bodhi-suspension-system' ? { fontWeight: '700' } : null}
								onClick={() => { handleSubNav('bodhi-suspension-system'); }}
							>
								Bodhi Suspension System
							</button>
						</li>
						<li className={NavStyles.navSubListItem}>
							<button
								className={`body-text ${ NavStyles.navSubListItemButton }`}
								style={selectedPath === 'oov' ? { fontWeight: '700' } : null}
								onClick={() => { handleSubNav('oov'); }}
							>
								Oov
							</button>
						</li>
						<li className={NavStyles.navSubListItem}>
							<button
								className={`body-text ${ NavStyles.navSubListItemButton }`}
								style={selectedPath === 'orbit' ? { fontWeight: '700' } : null}
								onClick={() => { handleSubNav('orbit'); }}
							>
								Orbit
							</button>
						</li>
						<li className={NavStyles.navSubListItem}>
							<button
								className={`body-text ${ NavStyles.navSubListItemButton }`}
								style={selectedPath === 'props' ? { fontWeight: '700' } : null}
								onClick={() => { handleSubNav('props'); }}
							>
								Props
							</button>
						</li>
					</ul>
				</li>

				{/* Item */}
				<li className={NavStyles.navTopListItem}>
					<button
						className={`h4 ${ NavStyles.navTopListItemButton }`}
						onClick={() => { handleAccordianOpen(5, 'studio-furnishings'); }}
					>
						Studio
					</button>
					{/* Sublist */}
					<ul className={openAccordian === 5
						? NavStyles.navSubList
						: NavStyles.navSubListCollapsed}
					>
						<li className={NavStyles.navSubListItem}>
							<button
								className={`body-text ${ NavStyles.navSubListItemButton }`}
								style={selectedPath === 'studio-furnishings' ? { fontWeight: '700' } : null}
								onClick={() => { handleSubNav('studio-furnishings'); }}
							>
								Studio Furnishings
							</button>
						</li>
						<li className={NavStyles.navSubListItem}>
							<button
								className={`body-text ${ NavStyles.navSubListItemButton }`}
								style={selectedPath === 'apparel' ? { fontWeight: '700' } : null}
								onClick={() => { handleSubNav('apparel'); }}
							>
								Apparel
							</button>
						</li>
						<li className={NavStyles.navSubListItem}>
							<button
								className={`body-text ${ NavStyles.navSubListItemButton }`}
								style={selectedPath === 'books-&-notes' ? { fontWeight: '700' } : null}
								onClick={() => { handleSubNav('books-&-notes'); }}
							>
								Books & Notes
							</button>
						</li>
						<li className={NavStyles.navSubListItem}>
							<button
								className={`body-text ${ NavStyles.navSubListItemButton }`}
								style={selectedPath === 'anatomy-+-movement' ? { fontWeight: '700' } : null}
								onClick={() => { handleSubNav('anatomy-+-movement'); }}
							>
								Anatomy + Movement
							</button>
						</li>
					</ul>
				</li>

				{/* Item */}
				<li className={NavStyles.navTopListItem}>
					<button
						className={`h4 ${ NavStyles.navTopListItemButton }`}
						onClick={() => { handleAccordianOpen(6, 'all'); }}
					>
						Show All
					</button>
				</li>
			</ul>

			{/* Go to pilates.com button */}
			<a
				href={'https://www.pilates.com'}
				target={'_blank'}
				rel={'noreferrer'}
				className={`flex flex-row flex-jc-sb ${ NavStyles.usaLink }`}
			>
				<p className={`body-text ${ NavStyles.pilatescomText }`}>Go to pilates.com</p>
				<Flag className={NavStyles.flag} />
			</a>

			{/* How to Purchase button */}
			<Button
				text={'How to Purchase'}
				type={'button'}
				action={() => {
					contextValue.handleModalOpen({
						...contextValue.modalState,
						open: true,
						content: 'info',
					});
				}}
				styles={{ width: '250px', height: '61px', marginBottom: '1rem' }}
			/>

			{/* How to Purchase button */}
			<Button
				text={'Have a Question?'}
				type={'button'}
				action={() => {
					contextValue.handleModalOpen({
						...contextValue.modalState,
						open: true,
						content: 'inquiries',
					});
				}}
				styles={{ width: '250px', height: '61px' }}
			/>

			{/* UK Active logo */}
			<UKActiveLogo className={NavStyles.navLogo} />

			{/* Independent Gyms logo */}
			<IndependentGymsLogo className={NavStyles.navLogo} />
		</nav>
	);
};

export default Nav;
